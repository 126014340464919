<template>
  <v-input v-if="signed">
    <div class="w-full">
      {{ $t("addenda") }}

      <v-sheet class="pa-4 mt-2 d-block" outlined rounded width="100%">
        <nl2br
          v-if="obInvoice.description"
          :value="obInvoice.description"
          tag="p"
        />
      </v-sheet>
    </div>
  </v-input>
  <v-textarea
    v-else
    v-model="obInvoice.description"
    :disabled="!positions.length || readOnly"
    :label="$t('addenda')"
    hide-details
    outlined
  />
</template>

<script lang="ts">
import { Invoice, InvoicePositionData } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import Nl2br from "@/components/common/Nl2br.vue";

@Component({
  components: { Nl2br },
})
export default class InvoiceAddenda extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obInvoice!: Invoice;
  @Prop(Boolean) readonly readOnly!: boolean;

  get positions(): InvoicePositionData[] {
    return this.obInvoice.get("positions", []);
  }

  get signed(): boolean {
    return this.obInvoice ? this.obInvoice.get("is_signed", false) : false;
  }
}
</script>
