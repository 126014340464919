<template>
  <v-dialog
    v-if="item"
    v-model="dialog"
    attach="#app"
    max-width="800px"
    open-delay="300"
    scrollable
    transition="dialog-top-transition"
    @input="onUpdateDialog"
  >
    <template v-if="!hideActivator" #activator="{ on }">
      <v-btn color="primary" small text v-on="on">
        {{ item.text }}
      </v-btn>
    </template>

    <v-carousel
      v-if="dialog"
      v-model="carousel"
      :show-arrows="false"
      height="auto"
      hide-delimiters
    >
      <v-carousel-item>
        <v-card
          :loading="loading"
          class="d-flex flex-column"
          flat
          height="100%"
        >
          <v-card-title
            v-if="item"
            class="text-h5 font-weight-light"
            v-text="item.text"
          />

          <v-divider />
          <v-card-subtitle v-text="$t('assigned.invoice.labels')" />
          <v-card-text>
            <company-label-list
              :hide-delete="signed"
              :items="inPosition ? arPositionLabels : arInvoiceFieldLabels"
              hide-update
              @delete="onRemove"
            />
          </v-card-text>

          <template v-if="!signed">
            <v-divider />
            <v-card-subtitle v-text="$t('available.invoice.labels')" />
            <v-card-text>
              <company-label-list
                :items="labels"
                hide-delete
                hide-update
                show-copy
                @copy="onApply"
              />
            </v-card-text>
          </template>

          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!signed"
              :disabled="loading"
              class="mr-4"
              color="success"
              text
              @click="onCreate"
            >
              {{ $t("create") }}
            </v-btn>
            <v-btn :disabled="loading" color="primary" text @click="onClose">
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-carousel-item>

      <v-carousel-item>
        <company-label-form
          v-model="obModel"
          :field="field"
          :go-back="!!arInvoiceLabels.length"
          :title="item ? item.text : undefined"
          @back="onBack"
          @cancel="onClose"
          @reload="onReload"
        />
      </v-carousel-item>
    </v-carousel>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { InvoiceModule } from "@/store/invoice";
import { LabelsModule } from "@/store/labels";
import { InvoiceLabelField } from "@/types/utils";
import { chain, delay, filter, find, map, omit } from "lodash";
import {
  InvoicePosition,
  Label,
  LabelCollection,
  LabelData,
} from "@planetadeleste/vue-mc-gw";
import CompanyLabelForm from "@/modules/companies/components/CompanyLabelForm.vue";
import CompanyLabelList from "@/modules/companies/components/CompanyLabelList.vue";

@Component({
  components: { CompanyLabelList, CompanyLabelForm },
})
export default class InvoiceLabelsDialog extends Vue {
  @VModel({ type: Boolean, default: false }) bDialog!: boolean;
  @Prop(String) readonly field!: InvoiceLabelField;
  @Prop([String, Number]) readonly product!: number;
  @Prop(Object) readonly position!: InvoicePosition;
  @Prop(Boolean) readonly hideActivator!: boolean;

  carousel = 0;
  dialog = false;
  loading = false;
  obModel: Label = new Label();
  obCollection: LabelCollection = new LabelCollection();

  get obInvoice() {
    return InvoiceModule.invoice;
  }

  get signed() {
    return InvoiceModule.signed;
  }

  get customerId(): number {
    return this.obInvoice.get("customer_id", 0);
  }

  get arInvoiceLabels(): Label[] {
    return map(
      this.obInvoice.get("labels", []),
      (obLabel: Label | LabelData) => {
        return obLabel instanceof Label ? obLabel : new Label(obLabel);
      }
    );
  }

  get arPositionLabels(): Label[] {
    return this.position
      ? map(this.position.get("labels", []), (obLabel: Label | LabelData) => {
          return obLabel instanceof Label ? obLabel : new Label(obLabel);
        })
      : [];
  }

  get arInvoiceFieldLabels(): Label[] {
    return filter(this.arInvoiceLabels, { field: this.field });
  }

  get labels() {
    return this.obCollection.getModels();
  }

  get fields() {
    return LabelsModule.fields;
  }

  get item() {
    return this.field
      ? find(LabelsModule.items, { value: this.field })
      : undefined;
  }

  get inCustomer() {
    return this.customerId && this.field == "InfoAdicional";
  }

  get inPosition() {
    return this.position && this.product && this.field === "DscItem";
  }

  @Watch("dialog")
  async onOpen(bValue: boolean) {
    this.$emit("input", bValue);

    if (!bValue) {
      return;
    }

    this.onBack();

    if (this.obCollection.length) {
      this.onSelectLabel(this.obCollection);
      return;
    }

    await this.loadLabels();
  }

  @Watch("customerId")
  async onChangeCustomer() {
    if (this.field !== "InfoAdicional") {
      return;
    }

    // Clean current labels
    this.obCollection.clear();

    // Reload default labels
    await this.loadLabels();

    // Load customer labels
    await this.loadLabels(true);

    // Clean currently auto assigned
    this.onCleanAssigned();

    // Auto assign customer labels
    this.onAutoAssign();
  }

  @Watch("bDialog")
  onChangeDialog(bValue: boolean) {
    this.dialog = bValue;
  }

  createLabel() {
    this.obModel = new Label({ field: this.field });

    if (this.inCustomer) {
      this.obModel.set("labelable_id", this.customerId);
      this.obModel.set("labelable_type", "Customer");
    } else if (this.inPosition) {
      this.obModel.set("labelable_id", this.product);
      this.obModel.set("labelable_type", "Product");
    }
  }

  onUpdateDialog(bValue: boolean) {
    this.$emit("input", bValue);
  }

  onBack() {
    this.carousel = 0;
    this.createLabel();
  }

  onClose() {
    this.dialog = false;
    this.onBack();
  }

  async onReload() {
    await this.loadLabels();
    this.onApply(this.obModel);
    this.onBack();
  }

  onApply(obLabel: Label) {
    const arLabelList = this.inPosition
      ? this.arPositionLabels
      : this.arInvoiceLabels;
    const obLabelsRelation = this.inPosition ? this.position : this.obInvoice;

    if (!find(arLabelList, { id: obLabel.id })) {
      obLabel = new Label(omit(obLabel.attributes, ["disabled"]));
      const arLabels = [...arLabelList];
      arLabels.push(obLabel);

      obLabelsRelation.set("labels", arLabels);
    }

    delay(() => {
      this.onSelectLabel(this.obCollection);
    }, 300);
  }

  onRemove(obLabel: Label | LabelData) {
    const arLabelList = this.inPosition
      ? this.arPositionLabels
      : this.arInvoiceLabels;
    const obLabelsRelation = this.inPosition ? this.position : this.obInvoice;
    const arLabels = filter(arLabelList, (obItem) => obItem.id !== obLabel.id);

    obLabelsRelation.set("labels", arLabels);
    this.onSelectLabel(this.obCollection);
  }

  onCreate() {
    this.createLabel();
    this.carousel = 1;
  }

  /**
   * Disable label models in the given collection based on the existing invoice labels.
   *
   * @param {LabelCollection} obCollection - The collection of label models.
   * @return {void}
   */
  onSelectLabel(obCollection: LabelCollection): void {
    const arLabelList = this.inPosition
      ? this.arPositionLabels
      : this.arInvoiceLabels;

    if (!arLabelList.length || !obCollection.length) {
      return;
    }

    obCollection.each((obModel) => {
      const obLabel = find(arLabelList, { id: obModel.id });
      obModel.set("disabled", !!obLabel);
    });
  }

  /**
   * Filter labels from the given label collection by nested one.
   *
   * @param {LabelCollection} obCollection - The label collection to filter.
   * @returns {LabelCollection} - The filtered label collection.
   */
  onFilterChildLabels(obCollection: LabelCollection): LabelCollection {
    const arLabelIdList = chain(obCollection.getModels())
      .filter((obModel: Label) => !!obModel.parent_id)
      .map("parent_id")
      .value() as number[];

    return arLabelIdList.length
      ? obCollection.filter(
          (obModel: Label) => !arLabelIdList.includes(obModel.id)
        )
      : obCollection;
  }

  onCleanAssigned() {
    if (!this.arInvoiceFieldLabels.length || this.inPosition) {
      return;
    }

    const arFilteredLabels = filter(this.arInvoiceLabels, (obLabel: Label) => {
      if (obLabel.field !== this.field) {
        return true;
      }

      return !obLabel.get("is_autoapply", false);
    });

    this.obInvoice.set("labels", arFilteredLabels);
  }

  /**
   * Executes the onApply method for each model in the obCollection that has the is_autoapply flag set to true.
   *
   * @returns {void} No value is returned.
   */
  onAutoAssign(): void {
    if (!this.obCollection.length) {
      return;
    }

    const arLabelList = this.inPosition
      ? this.arPositionLabels
      : this.arInvoiceFieldLabels;

    this.obCollection.each((obModel) => {
      if (obModel.get("is_autoapply", false)) {
        // Find parent model assigned
        if (obModel.parent_id) {
          const obLabel = find(arLabelList, {
            id: obModel.parent_id,
          });
          if (obLabel) {
            this.onRemove(obLabel);
          }
        }

        this.onApply(obModel);
      }
    });
  }

  /**
   * Loads labels for a specific field asynchronously.
   *
   * @param {boolean} append - Determines whether to append the labels to the existing collection. Defaults to false.
   * @return {Promise<void>} - Resolves when labels are successfully loaded.
   */
  async loadLabels(append: boolean = false): Promise<void> {
    if (!this.field) {
      return;
    }

    const obFilters: Record<string, any> = { field: this.field };

    if (!append) {
      obFilters.orphan = 1;
    } else {
      if (this.inCustomer) {
        obFilters.labelable = ["Customer", this.customerId];
      } else if (this.inPosition) {
        obFilters.labelable = ["Product", this.product];
      } else {
        return;
      }
    }

    this.loading = true;
    const obCollection = new LabelCollection();
    await obCollection.filterBy(obFilters).fetch();
    this.onSelectLabel(obCollection);

    if (append && this.obCollection.length) {
      const arLoadedLabelIdList = obCollection.map("id") as number[];
      const arLabelList = filter(
        this.obCollection.getModels(),
        (obModel) => !arLoadedLabelIdList.includes(obModel.id)
      );
      obCollection.add(arLabelList);
    }

    this.obCollection.clear();
    this.obCollection.add(this.onFilterChildLabels(obCollection).getModels());
    this.loading = false;
    this.carousel = 0;
  }

  async mounted() {
    if (!this.fields.length) {
      await LabelsModule.loadFields();
    }
    await this.loadLabels();

    if (this.inPosition || this.inCustomer) {
      await this.loadLabels(true);
    }

    this.onAutoAssign();
  }
}
</script>
